import { createSvgIcon } from '@mui/material';
import React from 'react';

export const SimpleViewIcon = createSvgIcon(
  <svg viewBox="0 0 17 17">
    <path d="M3.597 11.861c0 .713.577 1.29 1.29 1.29h7.226a1.29 1.29 0 0 0 0-2.58H4.887a1.29 1.29 0 0 0-1.29 1.29Z" />
    <path d="M.5 3.087A2.839 2.839 0 0 1 3.339.248H13.66a2.839 2.839 0 0 1 2.839 2.84V13.41a2.839 2.839 0 0 1-2.839 2.838H3.34A2.839 2.839 0 0 1 .5 13.41V3.087Zm2.839-1.29a1.29 1.29 0 0 0-1.29 1.29V13.41c0 .712.577 1.29 1.29 1.29H13.66a1.29 1.29 0 0 0 1.29-1.29V3.087a1.29 1.29 0 0 0-1.29-1.29H3.34Z" />
  </svg>,
  'SimpleView',
);

export const AdvancedViewIcon = createSvgIcon(
  <svg viewBox="0 0 17 16">
    <path d="M.5 3.087A2.839 2.839 0 0 1 3.339.248H13.66a2.839 2.839 0 1 1 0 5.678H3.34A2.839 2.839 0 0 1 .5 3.087Zm2.839-1.29a1.29 1.29 0 1 0 0 2.58H13.66a1.29 1.29 0 0 0 0-2.58H3.34ZM.5 9.28a2.839 2.839 0 0 1 2.839-2.838H13.66a2.839 2.839 0 0 1 2.84 2.839v3.096a2.839 2.839 0 0 1-2.839 2.84H3.34A2.839 2.839 0 0 1 .5 12.376V9.281Zm2.839-1.29a1.29 1.29 0 0 0-1.29 1.29v3.097c0 .713.577 1.29 1.29 1.29H13.66a1.29 1.29 0 0 0 1.29-1.29V9.281a1.29 1.29 0 0 0-1.29-1.29H3.34Z" />
    <path d="M13.403 10.83a1.29 1.29 0 1 1-2.58 0 1.29 1.29 0 0 1 2.58 0Zm-1.29-.259a.258.258 0 1 0 0 .516.258.258 0 0 0 0-.516Z" />
  </svg>,
  'AdvancedView',
);

export const DiscordIcon = createSvgIcon(
  <svg viewBox="0 0 17 16">
    <path d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z" />
  </svg>,
  'Discord',
);
